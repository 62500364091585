/**
 * @module
 * @description Handles clientSideLoggerObject's sent from within the code base and only allows
 * those with an event name of `deprecated_js` to be handled. Each object has a method name that
 * can only be logged once per page. When a turbo page navigation occurs, the method can be
 * logged again.
 */

import apiFetch from "../shared/api_fetch";

let deprecatedJsObjects = [];

/**
 * Will return true immediately upon finding a matching method name in deprecatedJsObjects.
 * Otherwise returns false.
 *
 * @function
 */
const alreadyLogged = (clientSideLoggerObject) =>
  deprecatedJsObjects.some(obj => obj.method === clientSideLoggerObject.method);

/**
 * Send the log object to our controller endpoint so it can be logged to Humio.
 *
 * @function
 */
const postDeprecatedJsObject = (clientSideLoggerObject) => {
  console.log(`sending log...${clientSideLoggerObject.method}`);
  const url = "/client_side_logs";
  apiFetch(url, { method: "post", body: JSON.stringify(clientSideLoggerObject) });
};

/**
 * We only want to send 1 log message for each method to know that the method is not deprecated. The
 * array deprecatedJsObjects only holds unique log events. If it doesn't contain a matching method
 * then add to the array. Otherwise the log message can be ignored.
 *
 * @function
 */
const limitDeprecatedJsObjects = (clientSideLoggerObject) => {
  if (alreadyLogged(clientSideLoggerObject)) return;

  deprecatedJsObjects.push(clientSideLoggerObject);
  postDeprecatedJsObject(clientSideLoggerObject);
};

/**
 * Ignore if the event name is not deprecated_js or if the object does not have a key named 'method'
 *
 * @function
 */
export const logDispatcher = (clientSideLoggerObject) => {
  if (clientSideLoggerObject.event !== "deprecated_js") return;
  if (!clientSideLoggerObject.method) return;

  limitDeprecatedJsObjects(clientSideLoggerObject);
};

/**
 * Clear deprecatedJsObjects on the 'turbo:before-visit' event so the following page can log
 * the message if it appears again on subsequent page visits.
 *
 * @function
 */
export const beforeVisitDispatcher = () => {
  deprecatedJsObjects = [];
};
