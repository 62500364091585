const maskableSections = [
  /^\d+$/, // matches ids
  /^\d*-\d*-\d*$/, // matches dates
  /^\d*-\d*-\d*_\d*-\d*-\d*$/, // matches date ranges
  /^\d*-\w*-\d*$/, // matches dates with named months
].map(regex => regex.source).join("|");

const maskableSectionRegex = new RegExp(maskableSections);

const maskUrl = (url) => {
  try {
    const path = new URL(url, window.location).pathname;
    return path.split("/").map((part) => {
      if (part.match(maskableSectionRegex)) {
        return "?";
      }
      return part;
    }).join("/");
  } catch (error) {
    return url;
  }
};

export default maskUrl;
