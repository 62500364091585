/* global Turbo */
/* eslint-disable max-classes-per-file */
import maskUrl from "./mask_url";

class ApiFetchError extends Error {
  constructor(url, code, statusText) {
    super(`URL: ${url}, HTTP error: ${code}, statusText: ${statusText}`);
    this.name = "ApiFetchError";
  }
}

class NetworkFetchError extends Error {
  constructor(url) {
    super(`URL: ${url}, Network error: Failed to fetch`);
    this.name = "NetworkFetchError";
  }
}

const handleReload = (response) => {
  if (response) {
    if (response.status === 401) {
      window.location.reload();
      return true;
    }
  }
  return false;
};

function buildApiOptions(options, headerOptions) {
  const apiOptions = {
    headers: {
      "X-KEYED-VALIDATIONS": true,
      "Content-Type": "application/json",
      ...headerOptions,
    },
    credentials: "include",
    ...options,
  };

  // If we have set the form content type to "multipart/form-data" as we are
  // send form data to the server we want to let fetch do its native thing rather than
  // us trying to tell it what to do.
  //
  // When this module was created we only dealt with json so made sense to make that the
  // default now we are using other types of post things are getting bit complicated with
  // code like this.
  if (apiOptions.headers["Content-Type"] === "multipart/form-data") {
    delete apiOptions.headers["Content-Type"];
  }
  const csrfToken = document.querySelector("meta[name='csrf-token']");
  if (csrfToken) {
    apiOptions.headers["X-CSRF-Token"] = csrfToken.content;
  }
  return apiOptions;
}

const contentTypeFor = (response) => {
  const contentType = response.headers.get("Content-Type");
  return contentType.split(";")[0];
};

const isLoginPage = (response) => response.url.includes("/login") || response.url.includes("/sessions/new");

const isRedirectToLoginPage = (response) => response.redirected && isLoginPage(response);

const isRedirectToHtmlPage = (response) => response.redirected && contentTypeFor(response) === "text/html";

const apiFetch = async (url, options = {}, headerOptions = {}) => {
  const apiOptions = buildApiOptions(options, headerOptions);
  let response;
  try {
    response = await fetch(url, apiOptions);
  } catch (error) {
    if (
      error.message === "Failed to fetch" || // chrome
      error.message === "Load failed" || // safari
      error.message === "NetworkError when attempting to fetch resource." // firefox
    ) {
      throw new NetworkFetchError(maskUrl(url));
    }
    throw error;
  }
  let responseBody;

  if (isRedirectToLoginPage(response)) {
    // Reload the browser to redirect the user back to login
    // doing it this way ensure the user is redirected back to
    // the correct page once they have logged back in.
    window.location.reload();
    return {};
  } else if (isRedirectToHtmlPage(response)) {
    if (Turbo) {
      Turbo.cache.clear();
      Turbo.visit(response.url, {
        response: {
          location: response.url,
          redirected: response.redirected,
          statusCode: response.status,
          responseHTML: await response.text(),
        },
      });
    } else {
      window.location = response.url;
    }

    return {};
  }

  if (response.ok) {
    if (response.status === 204) {
      responseBody = null;
    } else {
      switch (contentTypeFor(response)) {
        case "text/vnd.turbo-stream.html":
          responseBody = await response.text();
          break;
        default:
          responseBody = await response.json();
      }
    }

    return {
      body: responseBody,
      headers: response.headers,
      ok: response.ok,
      status: response.status,
    };
  }

  if (handleReload(response)) { return {}; }

  const error = new ApiFetchError(maskUrl(url), response.status, response.statusText);
  throw error;
};

export default apiFetch;
export { ApiFetchError };
