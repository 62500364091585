/**
 * @module
 * @description Creates a window.clientSideLogger public interface with a log function enabled. An
 * event listener is also enabled to handle turbo before-visit. This will allow us to log
 * front-end events when deprecated JS is called
 */

import { logDispatcher, beforeVisitDispatcher } from "./deprecated_js";

/**
 * @typedef {Object}
 * @property {function(Object)} log Forwards the sent data onto a dispatcher that will handle the
 * events. Note: if changing this function, ensure you update
 * `app/components/client_side_logging_component.rb` to match.
 */
const publicInterface = {
  log: (clientSideLoggerObject) => {
    logDispatcher(clientSideLoggerObject);
  },
};

/**
 * A turbo event is fired before visiting a location, except when navigating by history and
 * this sets up a dispatcher to handle when this happens.
 *
 * @function
 */
const eventListenerDispatchers = () => {
  window.addEventListener("turbo:before-visit", () => beforeVisitDispatcher());
};

/**
* If the client_side_logging feature has been enabled for the environment then a public interface
* with a log function is enabled with a dispatcher to handle the sent events. An event listener is
* also enabled to handle turbo before-visit.
*
* If client_side_logging is not enabled then an empty log function is created, allowing the
* logging to still be called from the code base but with no attached dispatchers.
*
* @function
*/
// eslint-disable-next-line import/prefer-default-export
export const init = () => {
  eventListenerDispatchers();
  window.clientSideLogger = publicInterface;
};
